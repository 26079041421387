import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import { RemoteAccLightRepo } from './AccLightRepo';
import { AccLightApplyTaskRq } from '@domain/rq/accLight/AccLightApplyTaskRq';
import { AccLightApplyTaskListRq } from '@domain/rq/accLight/AccLightApplyTaskListRq';
import { AccLightApplyHistoryRq } from '@domain/rq/accLight/AccLightApplyHistoryRq';
import { AccLightApplyTaskVO } from '@domain/vo/accLight/AccLightApplyTaskListVO';
import { AccLightApplyCompanyRecentVO } from '@domain/vo/accLight/AccLightApplyCompanyRecentVO';

const repo = new RemoteAccLightRepo();
// 불러올 과제 목록 조회
const fetchAccLightTasks = async (rq: AccLightApplyTaskListRq) => {
  // return new AccLightApplyTaskVO({
  //   requiredTasks: [
  //     {
  //       taskSn: 1,
  //       taskType: 'LIKERT' as any,
  //       taskName: 'Likert',
  //     },
  //     {
  //       taskSn: 1,
  //       taskType: 'META' as any,
  //       taskName: 'META',
  //     },
  //     {
  //       taskSn: 1,
  //       taskType: 'BIPOLAR' as any,
  //       taskName: 'BIPOLAR',
  //     },
  //     {
  //       taskSn: 1,
  //       taskType: 'MULTIPLE_CHOICE' as any,
  //       taskName: 'MULTIPLE_CHOICE',
  //     },
  //     {
  //       taskSn: 1,
  //       taskType: 'MRT' as any,
  //       taskName: 'MRT',
  //     },
  //     {
  //       taskSn: 1,
  //       taskType: 'RPS' as any,
  //       taskName: 'RPS',
  //     },
  //     {
  //       taskSn: 1,
  //       taskType: 'PM' as any,
  //       taskName: 'PM',
  //     },
  //     {
  //       taskSn: 1,
  //       taskType: 'RMT' as any,
  //       taskName: 'RMT',
  //     },
  //     {
  //       taskSn: 1,
  //       taskType: 'PCT2' as any,
  //       taskName: 'PCT2',
  //     },
  //     {
  //       taskSn: 1,
  //       taskType: 'FNB' as any,
  //       taskName: 'FNB',
  //     },
  //     {
  //       taskSn: 1,
  //       taskType: 'OTN' as any,
  //       taskName: 'OTN',
  //     },
  //     {
  //       taskSn: 1,
  //       taskType: 'WNC' as any,
  //       taskName: 'WNC',
  //     },
  //     {
  //       taskSn: 1,
  //       taskType: 'HAS' as any,
  //       taskName: 'HAS',
  //     },
  //     {
  //       taskSn: 1,
  //       taskType: 'VIDEO_BASIC_EXPERIENCE' as any,
  //       taskName: 'VIDEO_BASIC_EXPERIENCE',
  //     },
  //     {
  //       taskSn: 1,
  //       taskType: 'VIDEO_BASIC_VALUES' as any,
  //       taskName: 'VIDEO_BASIC_VALUES',
  //     },
  //     {
  //       taskSn: 1,
  //       taskType: 'VIDEO_BASIC_FREE_CHOICE' as any,
  //       taskName: 'VIDEO_BASIC_FREE_CHOICE',
  //     },
  //     {
  //       taskSn: 1,
  //       taskType: 'VIDEO_BASIC_SITUATION' as any,
  //       taskName: 'VIDEO_BASIC_SITUATION',
  //     },
  //   ],
  //   availableTasks: [
  //     {
  //       taskType: 'LIKERT' as any,
  //       taskName: '한글',
  //     },
  //   ],
  // });
  const { data } = await repo.fetchAccLightTasks(rq);
  return new AccLightApplyTaskVO(data);
};

//과제 불러오기
const postAccLightTasks = async (rq: AccLightApplyTaskRq) => {
  const { data } = await repo.postAccLightTasks(rq);
  return data;
};

//기업별 최근 응시내역 조회
const fetchAccLightApplyRecentCompany = async () => {
  const { data } = await repo.fetchAccLightApplyRecentCompany();
  return new AccLightApplyCompanyRecentVO(data);
};

//역검 라이트 결과표 리스트 조회
const fetchAccLightApplyHistory = async (rq: AccLightApplyHistoryRq) => {
  // return {
  //   historyList: [
  //     {
  //       title: '123',
  //       code: '1',
  //       completedDate: '2024-08-19T17:36:58',
  //       additionalNeededTaskCount: 14,
  //       sn: 545,
  //     },
  //     {
  //       title: '51',
  //       code: '2',
  //       completedDate: '2024-08-19T17:36:58',
  //       additionalNeededTaskCount: 14,
  //       sn: 545,
  //     },
  //     {
  //       title: '1234',
  //       code: '3',
  //       completedDate: '2024-08-19T17:36:58',
  //       additionalNeededTaskCount: 14,
  //       sn: 545,
  //     },
  //     {
  //       title: '123',
  //       code: '4',
  //       completedDate: '2024-08-19T17:36:58',
  //       additionalNeededTaskCount: 14,
  //       sn: 545,
  //     },
  //   ],
  // };
  const { data } = await repo.fetchAccLightApplyHistory(rq);
  return data;
};

//포지션 역검 이어하기
const fetchAccLightApplyContinue = async (positionSn: number) => {
  const { data } = await repo.fetchAccLightApplyContinue(positionSn);
  return data;
};

//포지션 역검 새로응시
const fetchAccLightNewApply = async (rq: AccLightApplyHistoryRq) => {
  const { data } = await repo.fetchAccLightNewApply(rq);
  return data;
};

export const useAccLightTasks = (rq: AccLightApplyTaskListRq) => {
  return useQuery(['accLight', 'tasks'], () => fetchAccLightTasks(rq));
};

export const useAccLightPostTasks = () => {
  return useMutation(postAccLightTasks);
};

export const useAccLightApplyRecentCompany = (options?: UseQueryOptions) => {
  return useQuery(['accLight', 'apply', 'recent', 'company'], fetchAccLightApplyRecentCompany, options as any);
};

export const useAccLightApplyHistory = (rq: AccLightApplyHistoryRq, options?: UseQueryOptions) => {
  return useQuery(['accLight', 'apply', 'history', rq], () => fetchAccLightApplyHistory(rq), options as any);
};

export const useAccLightApplyContinue = (positionSn: number, options?: UseQueryOptions) => {
  return useQuery(['accLight', 'apply', 'continue', positionSn], () => fetchAccLightApplyContinue(positionSn), {
    ...(options as any),
  });
};

export const useAccLightNewApply = () => {
  return useMutation(fetchAccLightNewApply);
};

import {
  AccLightApplyCompanyItemDto,
  AccLightApplyCompanyRecentRs,
} from '@domain/rs/accLight/AccLightApplyCompanyRecentRs';
import { ProgressType } from '@domain/constant/acca/ProgressType';
import DateFormat from '@utils/date/format';
import DateUtil from '@utils/date/util';

export class AccLightApplyCompanyRecentVO {
  applies: AccLightApplyCompanyItemVO[];
  accUrl: string;

  constructor(rs: AccLightApplyCompanyRecentRs) {
    this.accUrl = rs.accUrl;
    this.applies = rs.applies.map((item: any) => new AccLightApplyCompanyItemVO(item));
  }
}

export class AccLightApplyCompanyItemVO {
  sn: number;
  code: string;
  lastModifiedDateTime: string;
  title: string;
  completed: number;
  total: number;
  thumbnailUrl: string;
  submitDeadline: string | null;
  isEnd: boolean;
  badgeText: string | null;
  submitYn: boolean;
  percentage: string;
  saveYn: boolean;
  visibleYn: boolean;
  progressType: ProgressType;

  constructor(dto: AccLightApplyCompanyItemDto) {
    this.sn = dto.sn;
    this.code = dto.code;
    this.lastModifiedDateTime = DateFormat.format(new Date(dto.lastModifiedDateTime), 'yyyy.MM.dd HH:mm');
    this.title = dto.title;
    this.completed = dto.completed;
    this.total = dto.total;
    this.thumbnailUrl = dto.thumbnailUrl;
    this.submitDeadline = dto.submitDeadline;
    this.badgeText = this.getDday(dto.submitDeadline, dto.submitYn);
    this.isEnd = dto.submitDeadline ? DateUtil.isPastDate(new Date(dto.submitDeadline)) : false;
    this.submitYn = dto.submitYn;
    this.percentage = `${Math.ceil((dto.completed / dto.total) * 100)}%`;
    this.saveYn = dto.saveYn;
    this.visibleYn = dto.visibleYn;
    this.progressType = dto.progressType;
  }

  private getDday(date: string | null, submitYn: boolean) {
    if (submitYn) return '제출 완료';

    if (!date) return null;
    const tempDate = new Date(date);
    if (DateUtil.isPastDate(tempDate)) return '제출 마감';

    const result = DateUtil.getLeftDays(DateUtil.getNow(), tempDate);
    if (result === 0) return 'D-Day';

    return `D-${result}`;
  }
}
